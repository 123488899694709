.nb-theme-default nb-menu .menu-item a.active .menu-icon {
  color: #ec1c24;
}


.nb-theme-default nb-menu .menu-item a.active {
  color: #ec1c24;
}

.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a.active {
  color: #ec1c24;
}

.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a.active .menu-icon {
  color: #ec1c24;
}

.nb-theme-default ng2-smart-table th.ng2-smart-actions-title-add a {
  border-color: #ec1c24 !important;
  background-color: #ec1c24 !important;
}

.nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 1.25rem 2.25rem 0.75rem;
}
.text-bloque {
  color: #ff9c00 !important;
}
